const baseUrl = process.env.REACT_APP_API_URL;
export const Config = {
  apiUrl: baseUrl,
  profitAllUrl: process.env.REACT_APP_PROFITALL_URL,
  encodingKey: process.env.REACT_APP_ENCRYPT_KEY,
  // defaults: {
  //   paginator: {
  //     pageKey: "page",
  //     limitKey: "limit",
  //     searchKey: "search",
  //     limit: 10,
  //     startPage: 1,
  //   },
  // },
};
