export const countries = [
    {
        "name": "Nigeria",
        "iso2": "NG",
        "iso3": "NGA",
        "currency": "NGN",
        "currency_name": "Nigerian naira",
        "currency_symbol": "Nigerian naira",
        "phone_code": "234",
        "createdAt": "2023-05-17T10:59:16.034Z",
        "updatedAt": "2023-05-17T10:59:16.034Z",
        "id": "6464b38366ac86c570e8dac4"
    },
    {
        "name": "United Kingdom",
        "iso2": "GB",
        "iso3": "GBR",
        "currency": "GBP",
        "currency_name": "British pound",
        "currency_symbol": "British pound",
        "phone_code": "44",
        "createdAt": "2023-05-17T10:59:16.035Z",
        "updatedAt": "2023-05-17T10:59:16.035Z",
        "id": "6464b38466ac86c570e8db10"
    },
    {
        "name": "United States",
        "iso2": "US",
        "iso3": "USA",
        "currency": "USD",
        "currency_name": "United States dollar",
        "currency_symbol": "United States dollar",
        "phone_code": "1",
        "createdAt": "2023-05-17T10:59:16.035Z",
        "updatedAt": "2023-05-17T10:59:16.035Z",
        "id": "6464b38466ac86c570e8db11"
    },
    {
            "name": "Afghanistan",
            "iso2": "AF",
            "iso3": "AFG",
            "currency": "AFN",
            "currency_name": "Afghan afghani",
            "currency_symbol": "Afghan afghani",
            "phone_code": "93",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da26"
        },
        {
            "name": "Aland Islands",
            "iso2": "AX",
            "iso3": "ALA",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "358-18",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da27"
        },
        {
            "name": "Albania",
            "iso2": "AL",
            "iso3": "ALB",
            "currency": "ALL",
            "currency_name": "Albanian lek",
            "currency_symbol": "Albanian lek",
            "phone_code": "355",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da28"
        },
        {
            "name": "Algeria",
            "iso2": "DZ",
            "iso3": "DZA",
            "currency": "DZD",
            "currency_name": "Algerian dinar",
            "currency_symbol": "Algerian dinar",
            "phone_code": "213",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da29"
        },
        {
            "name": "American Samoa",
            "iso2": "AS",
            "iso3": "ASM",
            "currency": "USD",
            "currency_name": "US Dollar",
            "currency_symbol": "US Dollar",
            "phone_code": "1-684",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da2a"
        },
        {
            "name": "Andorra",
            "iso2": "AD",
            "iso3": "AND",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "376",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da2b"
        },
        {
            "name": "Angola",
            "iso2": "AO",
            "iso3": "AGO",
            "currency": "AOA",
            "currency_name": "Angolan kwanza",
            "currency_symbol": "Angolan kwanza",
            "phone_code": "244",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da2c"
        },
        {
            "name": "Anguilla",
            "iso2": "AI",
            "iso3": "AIA",
            "currency": "XCD",
            "currency_name": "East Caribbean dollar",
            "currency_symbol": "East Caribbean dollar",
            "phone_code": "1-264",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da2d"
        },
        {
            "name": "Antarctica",
            "iso2": "AQ",
            "iso3": "ATA",
            "currency": "AAD",
            "currency_name": "Antarctican dollar",
            "currency_symbol": "Antarctican dollar",
            "phone_code": "672",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da2e"
        },
        {
            "name": "Antigua And Barbuda",
            "iso2": "AG",
            "iso3": "ATG",
            "currency": "XCD",
            "currency_name": "Eastern Caribbean dollar",
            "currency_symbol": "Eastern Caribbean dollar",
            "phone_code": "1-268",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da2f"
        },
        {
            "name": "Argentina",
            "iso2": "AR",
            "iso3": "ARG",
            "currency": "ARS",
            "currency_name": "Argentine peso",
            "currency_symbol": "Argentine peso",
            "phone_code": "54",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da30"
        },
        {
            "name": "Armenia",
            "iso2": "AM",
            "iso3": "ARM",
            "currency": "AMD",
            "currency_name": "Armenian dram",
            "currency_symbol": "Armenian dram",
            "phone_code": "374",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da31"
        },
        {
            "name": "Aruba",
            "iso2": "AW",
            "iso3": "ABW",
            "currency": "AWG",
            "currency_name": "Aruban florin",
            "currency_symbol": "Aruban florin",
            "phone_code": "297",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da32"
        },
        {
            "name": "Australia",
            "iso2": "AU",
            "iso3": "AUS",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "61",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da33"
        },
        {
            "name": "Austria",
            "iso2": "AT",
            "iso3": "AUT",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "43",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da34"
        },
        {
            "name": "Azerbaijan",
            "iso2": "AZ",
            "iso3": "AZE",
            "currency": "AZN",
            "currency_name": "Azerbaijani manat",
            "currency_symbol": "Azerbaijani manat",
            "phone_code": "994",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da35"
        },
        {
            "name": "Bahrain",
            "iso2": "BH",
            "iso3": "BHR",
            "currency": "BHD",
            "currency_name": "Bahraini dinar",
            "currency_symbol": "Bahraini dinar",
            "phone_code": "973",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da36"
        },
        {
            "name": "Bangladesh",
            "iso2": "BD",
            "iso3": "BGD",
            "currency": "BDT",
            "currency_name": "Bangladeshi taka",
            "currency_symbol": "Bangladeshi taka",
            "phone_code": "880",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da37"
        },
        {
            "name": "Barbados",
            "iso2": "BB",
            "iso3": "BRB",
            "currency": "BBD",
            "currency_name": "Barbadian dollar",
            "currency_symbol": "Barbadian dollar",
            "phone_code": "1-246",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da38"
        },
        {
            "name": "Belarus",
            "iso2": "BY",
            "iso3": "BLR",
            "currency": "BYN",
            "currency_name": "Belarusian ruble",
            "currency_symbol": "Belarusian ruble",
            "phone_code": "375",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da39"
        },
        {
            "name": "Belgium",
            "iso2": "BE",
            "iso3": "BEL",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "32",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da3a"
        },
        {
            "name": "Belize",
            "iso2": "BZ",
            "iso3": "BLZ",
            "currency": "BZD",
            "currency_name": "Belize dollar",
            "currency_symbol": "Belize dollar",
            "phone_code": "501",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da3b"
        },
        {
            "name": "Benin",
            "iso2": "BJ",
            "iso3": "BEN",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "229",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da3c"
        },
        {
            "name": "Bermuda",
            "iso2": "BM",
            "iso3": "BMU",
            "currency": "BMD",
            "currency_name": "Bermudian dollar",
            "currency_symbol": "Bermudian dollar",
            "phone_code": "1-441",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da3d"
        },
        {
            "name": "Bhutan",
            "iso2": "BT",
            "iso3": "BTN",
            "currency": "BTN",
            "currency_name": "Bhutanese ngultrum",
            "currency_symbol": "Bhutanese ngultrum",
            "phone_code": "975",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da3e"
        },
        {
            "name": "Bolivia",
            "iso2": "BO",
            "iso3": "BOL",
            "currency": "BOB",
            "currency_name": "Bolivian boliviano",
            "currency_symbol": "Bolivian boliviano",
            "phone_code": "591",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da3f"
        },
        {
            "name": "Bonaire, Sint Eustatius and Saba",
            "iso2": "BQ",
            "iso3": "BES",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "599",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da40"
        },
        {
            "name": "Bosnia and Herzegovina",
            "iso2": "BA",
            "iso3": "BIH",
            "currency": "BAM",
            "currency_name": "Bosnia and Herzegovina convertible mark",
            "currency_symbol": "Bosnia and Herzegovina convertible mark",
            "phone_code": "387",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da41"
        },
        {
            "name": "Botswana",
            "iso2": "BW",
            "iso3": "BWA",
            "currency": "BWP",
            "currency_name": "Botswana pula",
            "currency_symbol": "Botswana pula",
            "phone_code": "267",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da42"
        },
        {
            "name": "Bouvet Island",
            "iso2": "BV",
            "iso3": "BVT",
            "currency": "NOK",
            "currency_name": "Norwegian Krone",
            "currency_symbol": "Norwegian Krone",
            "phone_code": "0055",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da43"
        },
        {
            "name": "Brazil",
            "iso2": "BR",
            "iso3": "BRA",
            "currency": "BRL",
            "currency_name": "Brazilian real",
            "currency_symbol": "Brazilian real",
            "phone_code": "55",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da44"
        },
        {
            "name": "British Indian Ocean Territory",
            "iso2": "IO",
            "iso3": "IOT",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "246",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da45"
        },
        {
            "name": "Brunei",
            "iso2": "BN",
            "iso3": "BRN",
            "currency": "BND",
            "currency_name": "Brunei dollar",
            "currency_symbol": "Brunei dollar",
            "phone_code": "673",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da46"
        },
        {
            "name": "Bulgaria",
            "iso2": "BG",
            "iso3": "BGR",
            "currency": "BGN",
            "currency_name": "Bulgarian lev",
            "currency_symbol": "Bulgarian lev",
            "phone_code": "359",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da47"
        },
        {
            "name": "Burkina Faso",
            "iso2": "BF",
            "iso3": "BFA",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "226",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da48"
        },
        {
            "name": "Burundi",
            "iso2": "BI",
            "iso3": "BDI",
            "currency": "BIF",
            "currency_name": "Burundian franc",
            "currency_symbol": "Burundian franc",
            "phone_code": "257",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da49"
        },
        {
            "name": "Cambodia",
            "iso2": "KH",
            "iso3": "KHM",
            "currency": "KHR",
            "currency_name": "Cambodian riel",
            "currency_symbol": "Cambodian riel",
            "phone_code": "855",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da4a"
        },
        {
            "name": "Cameroon",
            "iso2": "CM",
            "iso3": "CMR",
            "currency": "XAF",
            "currency_name": "Central African CFA franc",
            "currency_symbol": "Central African CFA franc",
            "phone_code": "237",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da4b"
        },
        {
            "name": "Canada",
            "iso2": "CA",
            "iso3": "CAN",
            "currency": "CAD",
            "currency_name": "Canadian dollar",
            "currency_symbol": "Canadian dollar",
            "phone_code": "1",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da4c"
        },
        {
            "name": "Cape Verde",
            "iso2": "CV",
            "iso3": "CPV",
            "currency": "CVE",
            "currency_name": "Cape Verdean escudo",
            "currency_symbol": "Cape Verdean escudo",
            "phone_code": "238",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da4d"
        },
        {
            "name": "Cayman Islands",
            "iso2": "KY",
            "iso3": "CYM",
            "currency": "KYD",
            "currency_name": "Cayman Islands dollar",
            "currency_symbol": "Cayman Islands dollar",
            "phone_code": "1-345",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da4e"
        },
        {
            "name": "Central African Republic",
            "iso2": "CF",
            "iso3": "CAF",
            "currency": "XAF",
            "currency_name": "Central African CFA franc",
            "currency_symbol": "Central African CFA franc",
            "phone_code": "236",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da4f"
        },
        {
            "name": "Chad",
            "iso2": "TD",
            "iso3": "TCD",
            "currency": "XAF",
            "currency_name": "Central African CFA franc",
            "currency_symbol": "Central African CFA franc",
            "phone_code": "235",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da50"
        },
        {
            "name": "Chile",
            "iso2": "CL",
            "iso3": "CHL",
            "currency": "CLP",
            "currency_name": "Chilean peso",
            "currency_symbol": "Chilean peso",
            "phone_code": "56",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da51"
        },
        {
            "name": "China",
            "iso2": "CN",
            "iso3": "CHN",
            "currency": "CNY",
            "currency_name": "Chinese yuan",
            "currency_symbol": "Chinese yuan",
            "phone_code": "86",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da52"
        },
        {
            "name": "Christmas Island",
            "iso2": "CX",
            "iso3": "CXR",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "61",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da53"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "iso2": "CC",
            "iso3": "CCK",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "61",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da54"
        },
        {
            "name": "Colombia",
            "iso2": "CO",
            "iso3": "COL",
            "currency": "COP",
            "currency_name": "Colombian peso",
            "currency_symbol": "Colombian peso",
            "phone_code": "57",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da55"
        },
        {
            "name": "Comoros",
            "iso2": "KM",
            "iso3": "COM",
            "currency": "KMF",
            "currency_name": "Comorian franc",
            "currency_symbol": "Comorian franc",
            "phone_code": "269",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da56"
        },
        {
            "name": "Congo",
            "iso2": "CG",
            "iso3": "COG",
            "currency": "XAF",
            "currency_name": "Central African CFA franc",
            "currency_symbol": "Central African CFA franc",
            "phone_code": "242",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da57"
        },
        {
            "name": "Cook Islands",
            "iso2": "CK",
            "iso3": "COK",
            "currency": "NZD",
            "currency_name": "Cook Islands dollar",
            "currency_symbol": "Cook Islands dollar",
            "phone_code": "682",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da58"
        },
        {
            "name": "Costa Rica",
            "iso2": "CR",
            "iso3": "CRI",
            "currency": "CRC",
            "currency_name": "Costa Rican colón",
            "currency_symbol": "Costa Rican colón",
            "phone_code": "506",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da59"
        },
        {
            "name": "Cote D'Ivoire (Ivory Coast)",
            "iso2": "CI",
            "iso3": "CIV",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "225",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da5a"
        },
        {
            "name": "Croatia",
            "iso2": "HR",
            "iso3": "HRV",
            "currency": "HRK",
            "currency_name": "Croatian kuna",
            "currency_symbol": "Croatian kuna",
            "phone_code": "385",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da5b"
        },
        {
            "name": "Cuba",
            "iso2": "CU",
            "iso3": "CUB",
            "currency": "CUP",
            "currency_name": "Cuban peso",
            "currency_symbol": "Cuban peso",
            "phone_code": "53",
            "createdAt": "2023-05-17T10:59:16.033Z",
            "updatedAt": "2023-05-17T10:59:16.033Z",
            "id": "6464b38366ac86c570e8da5c"
        },
        {
            "name": "Curaçao",
            "iso2": "CW",
            "iso3": "CUW",
            "currency": "ANG",
            "currency_name": "Netherlands Antillean guilder",
            "currency_symbol": "Netherlands Antillean guilder",
            "phone_code": "599",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da5d"
        },
        {
            "name": "Cyprus",
            "iso2": "CY",
            "iso3": "CYP",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "357",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da5e"
        },
        {
            "name": "Czech Republic",
            "iso2": "CZ",
            "iso3": "CZE",
            "currency": "CZK",
            "currency_name": "Czech koruna",
            "currency_symbol": "Czech koruna",
            "phone_code": "420",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da5f"
        },
        {
            "name": "Democratic Republic of the Congo",
            "iso2": "CD",
            "iso3": "COD",
            "currency": "CDF",
            "currency_name": "Congolese Franc",
            "currency_symbol": "Congolese Franc",
            "phone_code": "243",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da60"
        },
        {
            "name": "Denmark",
            "iso2": "DK",
            "iso3": "DNK",
            "currency": "DKK",
            "currency_name": "Danish krone",
            "currency_symbol": "Danish krone",
            "phone_code": "45",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da61"
        },
        {
            "name": "Djibouti",
            "iso2": "DJ",
            "iso3": "DJI",
            "currency": "DJF",
            "currency_name": "Djiboutian franc",
            "currency_symbol": "Djiboutian franc",
            "phone_code": "253",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da62"
        },
        {
            "name": "Dominica",
            "iso2": "DM",
            "iso3": "DMA",
            "currency": "XCD",
            "currency_name": "Eastern Caribbean dollar",
            "currency_symbol": "Eastern Caribbean dollar",
            "phone_code": "1-767",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da63"
        },
        {
            "name": "Dominican Republic",
            "iso2": "DO",
            "iso3": "DOM",
            "currency": "DOP",
            "currency_name": "Dominican peso",
            "currency_symbol": "Dominican peso",
            "phone_code": "1-829",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da64"
        },
        {
            "name": "East Timor",
            "iso2": "TL",
            "iso3": "TLS",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "670",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da65"
        },
        {
            "name": "Ecuador",
            "iso2": "EC",
            "iso3": "ECU",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "593",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da66"
        },
        {
            "name": "Egypt",
            "iso2": "EG",
            "iso3": "EGY",
            "currency": "EGP",
            "currency_name": "Egyptian pound",
            "currency_symbol": "Egyptian pound",
            "phone_code": "20",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da67"
        },
        {
            "name": "El Salvador",
            "iso2": "SV",
            "iso3": "SLV",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "503",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da68"
        },
        {
            "name": "Equatorial Guinea",
            "iso2": "GQ",
            "iso3": "GNQ",
            "currency": "XAF",
            "currency_name": "Central African CFA franc",
            "currency_symbol": "Central African CFA franc",
            "phone_code": "240",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da69"
        },
        {
            "name": "Eritrea",
            "iso2": "ER",
            "iso3": "ERI",
            "currency": "ERN",
            "currency_name": "Eritrean nakfa",
            "currency_symbol": "Eritrean nakfa",
            "phone_code": "291",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da6a"
        },
        {
            "name": "Estonia",
            "iso2": "EE",
            "iso3": "EST",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "372",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da6b"
        },
        {
            "name": "Ethiopia",
            "iso2": "ET",
            "iso3": "ETH",
            "currency": "ETB",
            "currency_name": "Ethiopian birr",
            "currency_symbol": "Ethiopian birr",
            "phone_code": "251",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da6c"
        },
        {
            "name": "Falkland Islands",
            "iso2": "FK",
            "iso3": "FLK",
            "currency": "FKP",
            "currency_name": "Falkland Islands pound",
            "currency_symbol": "Falkland Islands pound",
            "phone_code": "500",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da6d"
        },
        {
            "name": "Faroe Islands",
            "iso2": "FO",
            "iso3": "FRO",
            "currency": "DKK",
            "currency_name": "Danish krone",
            "currency_symbol": "Danish krone",
            "phone_code": "298",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da6e"
        },
        {
            "name": "Fiji Islands",
            "iso2": "FJ",
            "iso3": "FJI",
            "currency": "FJD",
            "currency_name": "Fijian dollar",
            "currency_symbol": "Fijian dollar",
            "phone_code": "679",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da6f"
        },
        {
            "name": "Finland",
            "iso2": "FI",
            "iso3": "FIN",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "358",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da70"
        },
        {
            "name": "France",
            "iso2": "FR",
            "iso3": "FRA",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "33",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da71"
        },
        {
            "name": "French Guiana",
            "iso2": "GF",
            "iso3": "GUF",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "594",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da72"
        },
        {
            "name": "French Polynesia",
            "iso2": "PF",
            "iso3": "PYF",
            "currency": "XPF",
            "currency_name": "CFP franc",
            "currency_symbol": "CFP franc",
            "phone_code": "689",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da73"
        },
        {
            "name": "French Southern Territories",
            "iso2": "TF",
            "iso3": "ATF",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "262",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da74"
        },
        {
            "name": "Gabon",
            "iso2": "GA",
            "iso3": "GAB",
            "currency": "XAF",
            "currency_name": "Central African CFA franc",
            "currency_symbol": "Central African CFA franc",
            "phone_code": "241",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da75"
        },
        {
            "name": "Gambia The",
            "iso2": "GM",
            "iso3": "GMB",
            "currency": "GMD",
            "currency_name": "Gambian dalasi",
            "currency_symbol": "Gambian dalasi",
            "phone_code": "220",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da76"
        },
        {
            "name": "Georgia",
            "iso2": "GE",
            "iso3": "GEO",
            "currency": "GEL",
            "currency_name": "Georgian lari",
            "currency_symbol": "Georgian lari",
            "phone_code": "995",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da77"
        },
        {
            "name": "Germany",
            "iso2": "DE",
            "iso3": "DEU",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "49",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da78"
        },
        {
            "name": "Ghana",
            "iso2": "GH",
            "iso3": "GHA",
            "currency": "GHS",
            "currency_name": "Ghanaian cedi",
            "currency_symbol": "Ghanaian cedi",
            "phone_code": "233",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da79"
        },
        {
            "name": "Gibraltar",
            "iso2": "GI",
            "iso3": "GIB",
            "currency": "GIP",
            "currency_name": "Gibraltar pound",
            "currency_symbol": "Gibraltar pound",
            "phone_code": "350",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da7a"
        },
        {
            "name": "Greece",
            "iso2": "GR",
            "iso3": "GRC",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "30",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da7b"
        },
        {
            "name": "Greenland",
            "iso2": "GL",
            "iso3": "GRL",
            "currency": "DKK",
            "currency_name": "Danish krone",
            "currency_symbol": "Danish krone",
            "phone_code": "299",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da7c"
        },
        {
            "name": "Grenada",
            "iso2": "GD",
            "iso3": "GRD",
            "currency": "XCD",
            "currency_name": "Eastern Caribbean dollar",
            "currency_symbol": "Eastern Caribbean dollar",
            "phone_code": "1-473",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da7d"
        },
        {
            "name": "Guadeloupe",
            "iso2": "GP",
            "iso3": "GLP",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "590",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da7e"
        },
        {
            "name": "Guam",
            "iso2": "GU",
            "iso3": "GUM",
            "currency": "USD",
            "currency_name": "US Dollar",
            "currency_symbol": "US Dollar",
            "phone_code": "1-671",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da7f"
        },
        {
            "name": "Guatemala",
            "iso2": "GT",
            "iso3": "GTM",
            "currency": "GTQ",
            "currency_name": "Guatemalan quetzal",
            "currency_symbol": "Guatemalan quetzal",
            "phone_code": "502",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da80"
        },
        {
            "name": "Guernsey and Alderney",
            "iso2": "GG",
            "iso3": "GGY",
            "currency": "GBP",
            "currency_name": "British pound",
            "currency_symbol": "British pound",
            "phone_code": "44-1481",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da81"
        },
        {
            "name": "Guinea",
            "iso2": "GN",
            "iso3": "GIN",
            "currency": "GNF",
            "currency_name": "Guinean franc",
            "currency_symbol": "Guinean franc",
            "phone_code": "224",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da82"
        },
        {
            "name": "Guinea-Bissau",
            "iso2": "GW",
            "iso3": "GNB",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "245",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da83"
        },
        {
            "name": "Guyana",
            "iso2": "GY",
            "iso3": "GUY",
            "currency": "GYD",
            "currency_name": "Guyanese dollar",
            "currency_symbol": "Guyanese dollar",
            "phone_code": "592",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da84"
        },
        {
            "name": "Haiti",
            "iso2": "HT",
            "iso3": "HTI",
            "currency": "HTG",
            "currency_name": "Haitian gourde",
            "currency_symbol": "Haitian gourde",
            "phone_code": "509",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da85"
        },
        {
            "name": "Heard Island and McDonald Islands",
            "iso2": "HM",
            "iso3": "HMD",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "672",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da86"
        },
        {
            "name": "Honduras",
            "iso2": "HN",
            "iso3": "HND",
            "currency": "HNL",
            "currency_name": "Honduran lempira",
            "currency_symbol": "Honduran lempira",
            "phone_code": "504",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da87"
        },
        {
            "name": "Hong Kong S.A.R.",
            "iso2": "HK",
            "iso3": "HKG",
            "currency": "HKD",
            "currency_name": "Hong Kong dollar",
            "currency_symbol": "Hong Kong dollar",
            "phone_code": "852",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da88"
        },
        {
            "name": "Hungary",
            "iso2": "HU",
            "iso3": "HUN",
            "currency": "HUF",
            "currency_name": "Hungarian forint",
            "currency_symbol": "Hungarian forint",
            "phone_code": "36",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da89"
        },
        {
            "name": "Iceland",
            "iso2": "IS",
            "iso3": "ISL",
            "currency": "ISK",
            "currency_name": "Icelandic króna",
            "currency_symbol": "Icelandic króna",
            "phone_code": "354",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da8a"
        },
        {
            "name": "India",
            "iso2": "IN",
            "iso3": "IND",
            "currency": "INR",
            "currency_name": "Indian rupee",
            "currency_symbol": "Indian rupee",
            "phone_code": "91",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da8b"
        },
        {
            "name": "Indonesia",
            "iso2": "ID",
            "iso3": "IDN",
            "currency": "IDR",
            "currency_name": "Indonesian rupiah",
            "currency_symbol": "Indonesian rupiah",
            "phone_code": "62",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da8c"
        },
        {
            "name": "Iran",
            "iso2": "IR",
            "iso3": "IRN",
            "currency": "IRR",
            "currency_name": "Iranian rial",
            "currency_symbol": "Iranian rial",
            "phone_code": "98",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da8d"
        },
        {
            "name": "Iraq",
            "iso2": "IQ",
            "iso3": "IRQ",
            "currency": "IQD",
            "currency_name": "Iraqi dinar",
            "currency_symbol": "Iraqi dinar",
            "phone_code": "964",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da8e"
        },
        {
            "name": "Ireland",
            "iso2": "IE",
            "iso3": "IRL",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "353",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da8f"
        },
        {
            "name": "Israel",
            "iso2": "IL",
            "iso3": "ISR",
            "currency": "ILS",
            "currency_name": "Israeli new shekel",
            "currency_symbol": "Israeli new shekel",
            "phone_code": "972",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da90"
        },
        {
            "name": "Italy",
            "iso2": "IT",
            "iso3": "ITA",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "39",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da91"
        },
        {
            "name": "Jamaica",
            "iso2": "JM",
            "iso3": "JAM",
            "currency": "JMD",
            "currency_name": "Jamaican dollar",
            "currency_symbol": "Jamaican dollar",
            "phone_code": "1-876",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da92"
        },
        {
            "name": "Japan",
            "iso2": "JP",
            "iso3": "JPN",
            "currency": "JPY",
            "currency_name": "Japanese yen",
            "currency_symbol": "Japanese yen",
            "phone_code": "81",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da93"
        },
        {
            "name": "Jersey",
            "iso2": "JE",
            "iso3": "JEY",
            "currency": "GBP",
            "currency_name": "British pound",
            "currency_symbol": "British pound",
            "phone_code": "44-1534",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da94"
        },
        {
            "name": "Jordan",
            "iso2": "JO",
            "iso3": "JOR",
            "currency": "JOD",
            "currency_name": "Jordanian dinar",
            "currency_symbol": "Jordanian dinar",
            "phone_code": "962",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da95"
        },
        {
            "name": "Kazakhstan",
            "iso2": "KZ",
            "iso3": "KAZ",
            "currency": "KZT",
            "currency_name": "Kazakhstani tenge",
            "currency_symbol": "Kazakhstani tenge",
            "phone_code": "7",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da96"
        },
        {
            "name": "Kenya",
            "iso2": "KE",
            "iso3": "KEN",
            "currency": "KES",
            "currency_name": "Kenyan shilling",
            "currency_symbol": "Kenyan shilling",
            "phone_code": "254",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da97"
        },
        {
            "name": "Kiribati",
            "iso2": "KI",
            "iso3": "KIR",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "686",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da98"
        },
        {
            "name": "Kosovo",
            "iso2": "XK",
            "iso3": "XKX",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "383",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da99"
        },
        {
            "name": "Kuwait",
            "iso2": "KW",
            "iso3": "KWT",
            "currency": "KWD",
            "currency_name": "Kuwaiti dinar",
            "currency_symbol": "Kuwaiti dinar",
            "phone_code": "965",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da9a"
        },
        {
            "name": "Kyrgyzstan",
            "iso2": "KG",
            "iso3": "KGZ",
            "currency": "KGS",
            "currency_name": "Kyrgyzstani som",
            "currency_symbol": "Kyrgyzstani som",
            "phone_code": "996",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da9b"
        },
        {
            "name": "Laos",
            "iso2": "LA",
            "iso3": "LAO",
            "currency": "LAK",
            "currency_name": "Lao kip",
            "currency_symbol": "Lao kip",
            "phone_code": "856",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da9c"
        },
        {
            "name": "Latvia",
            "iso2": "LV",
            "iso3": "LVA",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "371",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da9d"
        },
        {
            "name": "Lebanon",
            "iso2": "LB",
            "iso3": "LBN",
            "currency": "LBP",
            "currency_name": "Lebanese pound",
            "currency_symbol": "Lebanese pound",
            "phone_code": "961",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da9e"
        },
        {
            "name": "Lesotho",
            "iso2": "LS",
            "iso3": "LSO",
            "currency": "LSL",
            "currency_name": "Lesotho loti",
            "currency_symbol": "Lesotho loti",
            "phone_code": "266",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8da9f"
        },
        {
            "name": "Liberia",
            "iso2": "LR",
            "iso3": "LBR",
            "currency": "LRD",
            "currency_name": "Liberian dollar",
            "currency_symbol": "Liberian dollar",
            "phone_code": "231",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa0"
        },
        {
            "name": "Libya",
            "iso2": "LY",
            "iso3": "LBY",
            "currency": "LYD",
            "currency_name": "Libyan dinar",
            "currency_symbol": "Libyan dinar",
            "phone_code": "218",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa1"
        },
        {
            "name": "Liechtenstein",
            "iso2": "LI",
            "iso3": "LIE",
            "currency": "CHF",
            "currency_name": "Swiss franc",
            "currency_symbol": "Swiss franc",
            "phone_code": "423",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa2"
        },
        {
            "name": "Lithuania",
            "iso2": "LT",
            "iso3": "LTU",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "370",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa3"
        },
        {
            "name": "Luxembourg",
            "iso2": "LU",
            "iso3": "LUX",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "352",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa4"
        },
        {
            "name": "Macau S.A.R.",
            "iso2": "MO",
            "iso3": "MAC",
            "currency": "MOP",
            "currency_name": "Macanese pataca",
            "currency_symbol": "Macanese pataca",
            "phone_code": "853",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa5"
        },
        {
            "name": "Madagascar",
            "iso2": "MG",
            "iso3": "MDG",
            "currency": "MGA",
            "currency_name": "Malagasy ariary",
            "currency_symbol": "Malagasy ariary",
            "phone_code": "261",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa6"
        },
        {
            "name": "Malawi",
            "iso2": "MW",
            "iso3": "MWI",
            "currency": "MWK",
            "currency_name": "Malawian kwacha",
            "currency_symbol": "Malawian kwacha",
            "phone_code": "265",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa7"
        },
        {
            "name": "Malaysia",
            "iso2": "MY",
            "iso3": "MYS",
            "currency": "MYR",
            "currency_name": "Malaysian ringgit",
            "currency_symbol": "Malaysian ringgit",
            "phone_code": "60",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa8"
        },
        {
            "name": "Maldives",
            "iso2": "MV",
            "iso3": "MDV",
            "currency": "MVR",
            "currency_name": "Maldivian rufiyaa",
            "currency_symbol": "Maldivian rufiyaa",
            "phone_code": "960",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daa9"
        },
        {
            "name": "Mali",
            "iso2": "ML",
            "iso3": "MLI",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "223",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daaa"
        },
        {
            "name": "Malta",
            "iso2": "MT",
            "iso3": "MLT",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "356",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daab"
        },
        {
            "name": "Man (Isle of)",
            "iso2": "IM",
            "iso3": "IMN",
            "currency": "GBP",
            "currency_name": "British pound",
            "currency_symbol": "British pound",
            "phone_code": "44-1624",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daac"
        },
        {
            "name": "Marshall Islands",
            "iso2": "MH",
            "iso3": "MHL",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "692",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daad"
        },
        {
            "name": "Martinique",
            "iso2": "MQ",
            "iso3": "MTQ",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "596",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daae"
        },
        {
            "name": "Mauritania",
            "iso2": "MR",
            "iso3": "MRT",
            "currency": "MRO",
            "currency_name": "Mauritanian ouguiya",
            "currency_symbol": "Mauritanian ouguiya",
            "phone_code": "222",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daaf"
        },
        {
            "name": "Mauritius",
            "iso2": "MU",
            "iso3": "MUS",
            "currency": "MUR",
            "currency_name": "Mauritian rupee",
            "currency_symbol": "Mauritian rupee",
            "phone_code": "230",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab0"
        },
        {
            "name": "Mayotte",
            "iso2": "YT",
            "iso3": "MYT",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "262",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab1"
        },
        {
            "name": "Mexico",
            "iso2": "MX",
            "iso3": "MEX",
            "currency": "MXN",
            "currency_name": "Mexican peso",
            "currency_symbol": "Mexican peso",
            "phone_code": "52",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab2"
        },
        {
            "name": "Micronesia",
            "iso2": "FM",
            "iso3": "FSM",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "691",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab3"
        },
        {
            "name": "Moldova",
            "iso2": "MD",
            "iso3": "MDA",
            "currency": "MDL",
            "currency_name": "Moldovan leu",
            "currency_symbol": "Moldovan leu",
            "phone_code": "373",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab4"
        },
        {
            "name": "Monaco",
            "iso2": "MC",
            "iso3": "MCO",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "377",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab5"
        },
        {
            "name": "Mongolia",
            "iso2": "MN",
            "iso3": "MNG",
            "currency": "MNT",
            "currency_name": "Mongolian tögrög",
            "currency_symbol": "Mongolian tögrög",
            "phone_code": "976",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab6"
        },
        {
            "name": "Montenegro",
            "iso2": "ME",
            "iso3": "MNE",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "382",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab7"
        },
        {
            "name": "Montserrat",
            "iso2": "MS",
            "iso3": "MSR",
            "currency": "XCD",
            "currency_name": "Eastern Caribbean dollar",
            "currency_symbol": "Eastern Caribbean dollar",
            "phone_code": "1-664",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab8"
        },
        {
            "name": "Morocco",
            "iso2": "MA",
            "iso3": "MAR",
            "currency": "MAD",
            "currency_name": "Moroccan dirham",
            "currency_symbol": "Moroccan dirham",
            "phone_code": "212",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dab9"
        },
        {
            "name": "Mozambique",
            "iso2": "MZ",
            "iso3": "MOZ",
            "currency": "MZN",
            "currency_name": "Mozambican metical",
            "currency_symbol": "Mozambican metical",
            "phone_code": "258",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8daba"
        },
        {
            "name": "Myanmar",
            "iso2": "MM",
            "iso3": "MMR",
            "currency": "MMK",
            "currency_name": "Burmese kyat",
            "currency_symbol": "Burmese kyat",
            "phone_code": "95",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dabb"
        },
        {
            "name": "Namibia",
            "iso2": "NA",
            "iso3": "NAM",
            "currency": "NAD",
            "currency_name": "Namibian dollar",
            "currency_symbol": "Namibian dollar",
            "phone_code": "264",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dabc"
        },
        {
            "name": "Nauru",
            "iso2": "NR",
            "iso3": "NRU",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "674",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dabd"
        },
        {
            "name": "Nepal",
            "iso2": "NP",
            "iso3": "NPL",
            "currency": "NPR",
            "currency_name": "Nepalese rupee",
            "currency_symbol": "Nepalese rupee",
            "phone_code": "977",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dabe"
        },
        {
            "name": "Netherlands",
            "iso2": "NL",
            "iso3": "NLD",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "31",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dabf"
        },
        {
            "name": "New Caledonia",
            "iso2": "NC",
            "iso3": "NCL",
            "currency": "XPF",
            "currency_name": "CFP franc",
            "currency_symbol": "CFP franc",
            "phone_code": "687",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dac0"
        },
        {
            "name": "New Zealand",
            "iso2": "NZ",
            "iso3": "NZL",
            "currency": "NZD",
            "currency_name": "New Zealand dollar",
            "currency_symbol": "New Zealand dollar",
            "phone_code": "64",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dac1"
        },
        {
            "name": "Nicaragua",
            "iso2": "NI",
            "iso3": "NIC",
            "currency": "NIO",
            "currency_name": "Nicaraguan córdoba",
            "currency_symbol": "Nicaraguan córdoba",
            "phone_code": "505",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dac2"
        },
        {
            "name": "Niger",
            "iso2": "NE",
            "iso3": "NER",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "227",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dac3"
        },
        {
            "name": "Niue",
            "iso2": "NU",
            "iso3": "NIU",
            "currency": "NZD",
            "currency_name": "New Zealand dollar",
            "currency_symbol": "New Zealand dollar",
            "phone_code": "683",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dac5"
        },
        {
            "name": "Norfolk Island",
            "iso2": "NF",
            "iso3": "NFK",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "672",
            "createdAt": "2023-05-17T10:59:16.034Z",
            "updatedAt": "2023-05-17T10:59:16.034Z",
            "id": "6464b38366ac86c570e8dac6"
        },
        {
            "name": "North Korea",
            "iso2": "KP",
            "iso3": "PRK",
            "currency": "KPW",
            "currency_name": "North Korean Won",
            "currency_symbol": "North Korean Won",
            "phone_code": "850",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38366ac86c570e8dac7"
        },
        {
            "name": "North Macedonia",
            "iso2": "MK",
            "iso3": "MKD",
            "currency": "MKD",
            "currency_name": "Denar",
            "currency_symbol": "Denar",
            "phone_code": "389",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38366ac86c570e8dac8"
        },
        {
            "name": "Northern Mariana Islands",
            "iso2": "MP",
            "iso3": "MNP",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "1-670",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38366ac86c570e8dac9"
        },
        {
            "name": "Norway",
            "iso2": "NO",
            "iso3": "NOR",
            "currency": "NOK",
            "currency_name": "Norwegian krone",
            "currency_symbol": "Norwegian krone",
            "phone_code": "47",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daca"
        },
        {
            "name": "Oman",
            "iso2": "OM",
            "iso3": "OMN",
            "currency": "OMR",
            "currency_name": "Omani rial",
            "currency_symbol": "Omani rial",
            "phone_code": "968",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dacb"
        },
        {
            "name": "Pakistan",
            "iso2": "PK",
            "iso3": "PAK",
            "currency": "PKR",
            "currency_name": "Pakistani rupee",
            "currency_symbol": "Pakistani rupee",
            "phone_code": "92",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dacc"
        },
        {
            "name": "Palau",
            "iso2": "PW",
            "iso3": "PLW",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "680",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dacd"
        },
        {
            "name": "Palestinian Territory Occupied",
            "iso2": "PS",
            "iso3": "PSE",
            "currency": "ILS",
            "currency_name": "Israeli new shekel",
            "currency_symbol": "Israeli new shekel",
            "phone_code": "970",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dace"
        },
        {
            "name": "Panama",
            "iso2": "PA",
            "iso3": "PAN",
            "currency": "PAB",
            "currency_name": "Panamanian balboa",
            "currency_symbol": "Panamanian balboa",
            "phone_code": "507",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dacf"
        },
        {
            "name": "Papua new Guinea",
            "iso2": "PG",
            "iso3": "PNG",
            "currency": "PGK",
            "currency_name": "Papua New Guinean kina",
            "currency_symbol": "Papua New Guinean kina",
            "phone_code": "675",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad0"
        },
        {
            "name": "Paraguay",
            "iso2": "PY",
            "iso3": "PRY",
            "currency": "PYG",
            "currency_name": "Paraguayan guarani",
            "currency_symbol": "Paraguayan guarani",
            "phone_code": "595",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad1"
        },
        {
            "name": "Peru",
            "iso2": "PE",
            "iso3": "PER",
            "currency": "PEN",
            "currency_name": "Peruvian sol",
            "currency_symbol": "Peruvian sol",
            "phone_code": "51",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad2"
        },
        {
            "name": "Philippines",
            "iso2": "PH",
            "iso3": "PHL",
            "currency": "PHP",
            "currency_name": "Philippine peso",
            "currency_symbol": "Philippine peso",
            "phone_code": "63",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad3"
        },
        {
            "name": "Pitcairn Island",
            "iso2": "PN",
            "iso3": "PCN",
            "currency": "NZD",
            "currency_name": "New Zealand dollar",
            "currency_symbol": "New Zealand dollar",
            "phone_code": "870",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad4"
        },
        {
            "name": "Poland",
            "iso2": "PL",
            "iso3": "POL",
            "currency": "PLN",
            "currency_name": "Polish złoty",
            "currency_symbol": "Polish złoty",
            "phone_code": "48",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad5"
        },
        {
            "name": "Portugal",
            "iso2": "PT",
            "iso3": "PRT",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "351",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad6"
        },
        {
            "name": "Puerto Rico",
            "iso2": "PR",
            "iso3": "PRI",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "1-939",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad7"
        },
        {
            "name": "Qatar",
            "iso2": "QA",
            "iso3": "QAT",
            "currency": "QAR",
            "currency_name": "Qatari riyal",
            "currency_symbol": "Qatari riyal",
            "phone_code": "974",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad8"
        },
        {
            "name": "Reunion",
            "iso2": "RE",
            "iso3": "REU",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "262",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dad9"
        },
        {
            "name": "Romania",
            "iso2": "RO",
            "iso3": "ROU",
            "currency": "RON",
            "currency_name": "Romanian leu",
            "currency_symbol": "Romanian leu",
            "phone_code": "40",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dada"
        },
        {
            "name": "Russia",
            "iso2": "RU",
            "iso3": "RUS",
            "currency": "RUB",
            "currency_name": "Russian ruble",
            "currency_symbol": "Russian ruble",
            "phone_code": "7",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dadb"
        },
        {
            "name": "Rwanda",
            "iso2": "RW",
            "iso3": "RWA",
            "currency": "RWF",
            "currency_name": "Rwandan franc",
            "currency_symbol": "Rwandan franc",
            "phone_code": "250",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dadc"
        },
        {
            "name": "Saint Helena",
            "iso2": "SH",
            "iso3": "SHN",
            "currency": "SHP",
            "currency_name": "Saint Helena pound",
            "currency_symbol": "Saint Helena pound",
            "phone_code": "290",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dadd"
        },
        {
            "name": "Saint Kitts And Nevis",
            "iso2": "KN",
            "iso3": "KNA",
            "currency": "XCD",
            "currency_name": "Eastern Caribbean dollar",
            "currency_symbol": "Eastern Caribbean dollar",
            "phone_code": "1-869",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dade"
        },
        {
            "name": "Saint Lucia",
            "iso2": "LC",
            "iso3": "LCA",
            "currency": "XCD",
            "currency_name": "Eastern Caribbean dollar",
            "currency_symbol": "Eastern Caribbean dollar",
            "phone_code": "1-758",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dadf"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "iso2": "PM",
            "iso3": "SPM",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "508",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae0"
        },
        {
            "name": "Saint Vincent And The Grenadines",
            "iso2": "VC",
            "iso3": "VCT",
            "currency": "XCD",
            "currency_name": "Eastern Caribbean dollar",
            "currency_symbol": "Eastern Caribbean dollar",
            "phone_code": "1-784",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae1"
        },
        {
            "name": "Saint-Barthelemy",
            "iso2": "BL",
            "iso3": "BLM",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "590",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae2"
        },
        {
            "name": "Saint-Martin (French part)",
            "iso2": "MF",
            "iso3": "MAF",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "590",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae3"
        },
        {
            "name": "Samoa",
            "iso2": "WS",
            "iso3": "WSM",
            "currency": "WST",
            "currency_name": "Samoan tālā",
            "currency_symbol": "Samoan tālā",
            "phone_code": "685",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae4"
        },
        {
            "name": "San Marino",
            "iso2": "SM",
            "iso3": "SMR",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "378",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae5"
        },
        {
            "name": "Sao Tome and Principe",
            "iso2": "ST",
            "iso3": "STP",
            "currency": "STD",
            "currency_name": "Dobra",
            "currency_symbol": "Dobra",
            "phone_code": "239",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae6"
        },
        {
            "name": "Saudi Arabia",
            "iso2": "SA",
            "iso3": "SAU",
            "currency": "SAR",
            "currency_name": "Saudi riyal",
            "currency_symbol": "Saudi riyal",
            "phone_code": "966",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae7"
        },
        {
            "name": "Senegal",
            "iso2": "SN",
            "iso3": "SEN",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "221",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae8"
        },
        {
            "name": "Serbia",
            "iso2": "RS",
            "iso3": "SRB",
            "currency": "RSD",
            "currency_name": "Serbian dinar",
            "currency_symbol": "Serbian dinar",
            "phone_code": "381",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dae9"
        },
        {
            "name": "Seychelles",
            "iso2": "SC",
            "iso3": "SYC",
            "currency": "SCR",
            "currency_name": "Seychellois rupee",
            "currency_symbol": "Seychellois rupee",
            "phone_code": "248",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daea"
        },
        {
            "name": "Sierra Leone",
            "iso2": "SL",
            "iso3": "SLE",
            "currency": "SLL",
            "currency_name": "Sierra Leonean leone",
            "currency_symbol": "Sierra Leonean leone",
            "phone_code": "232",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daeb"
        },
        {
            "name": "Singapore",
            "iso2": "SG",
            "iso3": "SGP",
            "currency": "SGD",
            "currency_name": "Singapore dollar",
            "currency_symbol": "Singapore dollar",
            "phone_code": "65",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daec"
        },
        {
            "name": "Sint Maarten (Dutch part)",
            "iso2": "SX",
            "iso3": "SXM",
            "currency": "ANG",
            "currency_name": "Netherlands Antillean guilder",
            "currency_symbol": "Netherlands Antillean guilder",
            "phone_code": "1721",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daed"
        },
        {
            "name": "Slovakia",
            "iso2": "SK",
            "iso3": "SVK",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "421",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daee"
        },
        {
            "name": "Slovenia",
            "iso2": "SI",
            "iso3": "SVN",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "386",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daef"
        },
        {
            "name": "Solomon Islands",
            "iso2": "SB",
            "iso3": "SLB",
            "currency": "SBD",
            "currency_name": "Solomon Islands dollar",
            "currency_symbol": "Solomon Islands dollar",
            "phone_code": "677",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf0"
        },
        {
            "name": "Somalia",
            "iso2": "SO",
            "iso3": "SOM",
            "currency": "SOS",
            "currency_name": "Somali shilling",
            "currency_symbol": "Somali shilling",
            "phone_code": "252",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf1"
        },
        {
            "name": "South Africa",
            "iso2": "ZA",
            "iso3": "ZAF",
            "currency": "ZAR",
            "currency_name": "South African rand",
            "currency_symbol": "South African rand",
            "phone_code": "27",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf2"
        },
        {
            "name": "South Georgia",
            "iso2": "GS",
            "iso3": "SGS",
            "currency": "GBP",
            "currency_name": "British pound",
            "currency_symbol": "British pound",
            "phone_code": "500",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf3"
        },
        {
            "name": "South Korea",
            "iso2": "KR",
            "iso3": "KOR",
            "currency": "KRW",
            "currency_name": "Won",
            "currency_symbol": "Won",
            "phone_code": "82",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf4"
        },
        {
            "name": "South Sudan",
            "iso2": "SS",
            "iso3": "SSD",
            "currency": "SSP",
            "currency_name": "South Sudanese pound",
            "currency_symbol": "South Sudanese pound",
            "phone_code": "211",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf5"
        },
        {
            "name": "Spain",
            "iso2": "ES",
            "iso3": "ESP",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "34",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf6"
        },
        {
            "name": "Sri Lanka",
            "iso2": "LK",
            "iso3": "LKA",
            "currency": "LKR",
            "currency_name": "Sri Lankan rupee",
            "currency_symbol": "Sri Lankan rupee",
            "phone_code": "94",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf7"
        },
        {
            "name": "Sudan",
            "iso2": "SD",
            "iso3": "SDN",
            "currency": "SDG",
            "currency_name": "Sudanese pound",
            "currency_symbol": "Sudanese pound",
            "phone_code": "249",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf8"
        },
        {
            "name": "Suriname",
            "iso2": "SR",
            "iso3": "SUR",
            "currency": "SRD",
            "currency_name": "Surinamese dollar",
            "currency_symbol": "Surinamese dollar",
            "phone_code": "597",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daf9"
        },
        {
            "name": "Svalbard And Jan Mayen Islands",
            "iso2": "SJ",
            "iso3": "SJM",
            "currency": "NOK",
            "currency_name": "Norwegian Krone",
            "currency_symbol": "Norwegian Krone",
            "phone_code": "47",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dafa"
        },
        {
            "name": "Swaziland",
            "iso2": "SZ",
            "iso3": "SWZ",
            "currency": "SZL",
            "currency_name": "Lilangeni",
            "currency_symbol": "Lilangeni",
            "phone_code": "268",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dafb"
        },
        {
            "name": "Sweden",
            "iso2": "SE",
            "iso3": "SWE",
            "currency": "SEK",
            "currency_name": "Swedish krona",
            "currency_symbol": "Swedish krona",
            "phone_code": "46",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dafc"
        },
        {
            "name": "Switzerland",
            "iso2": "CH",
            "iso3": "CHE",
            "currency": "CHF",
            "currency_name": "Swiss franc",
            "currency_symbol": "Swiss franc",
            "phone_code": "41",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dafd"
        },
        {
            "name": "Syria",
            "iso2": "SY",
            "iso3": "SYR",
            "currency": "SYP",
            "currency_name": "Syrian pound",
            "currency_symbol": "Syrian pound",
            "phone_code": "963",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8dafe"
        },
        {
            "name": "Taiwan",
            "iso2": "TW",
            "iso3": "TWN",
            "currency": "TWD",
            "currency_name": "New Taiwan dollar",
            "currency_symbol": "New Taiwan dollar",
            "phone_code": "886",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8daff"
        },
        {
            "name": "Tajikistan",
            "iso2": "TJ",
            "iso3": "TJK",
            "currency": "TJS",
            "currency_name": "Tajikistani somoni",
            "currency_symbol": "Tajikistani somoni",
            "phone_code": "992",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db00"
        },
        {
            "name": "Tanzania",
            "iso2": "TZ",
            "iso3": "TZA",
            "currency": "TZS",
            "currency_name": "Tanzanian shilling",
            "currency_symbol": "Tanzanian shilling",
            "phone_code": "255",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db01"
        },
        {
            "name": "Thailand",
            "iso2": "TH",
            "iso3": "THA",
            "currency": "THB",
            "currency_name": "Thai baht",
            "currency_symbol": "Thai baht",
            "phone_code": "66",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db02"
        },
        {
            "name": "The Bahamas",
            "iso2": "BS",
            "iso3": "BHS",
            "currency": "BSD",
            "currency_name": "Bahamian dollar",
            "currency_symbol": "Bahamian dollar",
            "phone_code": "1-242",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db03"
        },
        {
            "name": "Togo",
            "iso2": "TG",
            "iso3": "TGO",
            "currency": "XOF",
            "currency_name": "West African CFA franc",
            "currency_symbol": "West African CFA franc",
            "phone_code": "228",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db04"
        },
        {
            "name": "Tokelau",
            "iso2": "TK",
            "iso3": "TKL",
            "currency": "NZD",
            "currency_name": "New Zealand dollar",
            "currency_symbol": "New Zealand dollar",
            "phone_code": "690",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db05"
        },
        {
            "name": "Tonga",
            "iso2": "TO",
            "iso3": "TON",
            "currency": "TOP",
            "currency_name": "Tongan paʻanga",
            "currency_symbol": "Tongan paʻanga",
            "phone_code": "676",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db06"
        },
        {
            "name": "Trinidad And Tobago",
            "iso2": "TT",
            "iso3": "TTO",
            "currency": "TTD",
            "currency_name": "Trinidad and Tobago dollar",
            "currency_symbol": "Trinidad and Tobago dollar",
            "phone_code": "1-868",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db07"
        },
        {
            "name": "Tunisia",
            "iso2": "TN",
            "iso3": "TUN",
            "currency": "TND",
            "currency_name": "Tunisian dinar",
            "currency_symbol": "Tunisian dinar",
            "phone_code": "216",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db08"
        },
        {
            "name": "Turkey",
            "iso2": "TR",
            "iso3": "TUR",
            "currency": "TRY",
            "currency_name": "Turkish lira",
            "currency_symbol": "Turkish lira",
            "phone_code": "90",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db09"
        },
        {
            "name": "Turkmenistan",
            "iso2": "TM",
            "iso3": "TKM",
            "currency": "TMT",
            "currency_name": "Turkmenistan manat",
            "currency_symbol": "Turkmenistan manat",
            "phone_code": "993",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db0a"
        },
        {
            "name": "Turks And Caicos Islands",
            "iso2": "TC",
            "iso3": "TCA",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "1-649",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db0b"
        },
        {
            "name": "Tuvalu",
            "iso2": "TV",
            "iso3": "TUV",
            "currency": "AUD",
            "currency_name": "Australian dollar",
            "currency_symbol": "Australian dollar",
            "phone_code": "688",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db0c"
        },
        {
            "name": "Uganda",
            "iso2": "UG",
            "iso3": "UGA",
            "currency": "UGX",
            "currency_name": "Ugandan shilling",
            "currency_symbol": "Ugandan shilling",
            "phone_code": "256",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db0d"
        },
        {
            "name": "Ukraine",
            "iso2": "UA",
            "iso3": "UKR",
            "currency": "UAH",
            "currency_name": "Ukrainian hryvnia",
            "currency_symbol": "Ukrainian hryvnia",
            "phone_code": "380",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db0e"
        },
        {
            "name": "United Arab Emirates",
            "iso2": "AE",
            "iso3": "ARE",
            "currency": "AED",
            "currency_name": "United Arab Emirates dirham",
            "currency_symbol": "United Arab Emirates dirham",
            "phone_code": "971",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db0f"
        },
        {
            "name": "United States Minor Outlying Islands",
            "iso2": "UM",
            "iso3": "UMI",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "1",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db12"
        },
        {
            "name": "Uruguay",
            "iso2": "UY",
            "iso3": "URY",
            "currency": "UYU",
            "currency_name": "Uruguayan peso",
            "currency_symbol": "Uruguayan peso",
            "phone_code": "598",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db13"
        },
        {
            "name": "Uzbekistan",
            "iso2": "UZ",
            "iso3": "UZB",
            "currency": "UZS",
            "currency_name": "Uzbekistani soʻm",
            "currency_symbol": "Uzbekistani soʻm",
            "phone_code": "998",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db14"
        },
        {
            "name": "Vanuatu",
            "iso2": "VU",
            "iso3": "VUT",
            "currency": "VUV",
            "currency_name": "Vanuatu vatu",
            "currency_symbol": "Vanuatu vatu",
            "phone_code": "678",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db15"
        },
        {
            "name": "Vatican City State (Holy See)",
            "iso2": "VA",
            "iso3": "VAT",
            "currency": "EUR",
            "currency_name": "Euro",
            "currency_symbol": "Euro",
            "phone_code": "379",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db16"
        },
        {
            "name": "Venezuela",
            "iso2": "VE",
            "iso3": "VEN",
            "currency": "VEF",
            "currency_name": "Bolívar",
            "currency_symbol": "Bolívar",
            "phone_code": "58",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db17"
        },
        {
            "name": "Vietnam",
            "iso2": "VN",
            "iso3": "VNM",
            "currency": "VND",
            "currency_name": "Vietnamese đồng",
            "currency_symbol": "Vietnamese đồng",
            "phone_code": "84",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db18"
        },
        {
            "name": "Virgin Islands (British)",
            "iso2": "VG",
            "iso3": "VGB",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "1-284",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db19"
        },
        {
            "name": "Virgin Islands (US)",
            "iso2": "VI",
            "iso3": "VIR",
            "currency": "USD",
            "currency_name": "United States dollar",
            "currency_symbol": "United States dollar",
            "phone_code": "1-340",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db1a"
        },
        {
            "name": "Wallis And Futuna Islands",
            "iso2": "WF",
            "iso3": "WLF",
            "currency": "XPF",
            "currency_name": "CFP franc",
            "currency_symbol": "CFP franc",
            "phone_code": "681",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db1b"
        },
        {
            "name": "Western Sahara",
            "iso2": "EH",
            "iso3": "ESH",
            "currency": "MAD",
            "currency_name": "Moroccan Dirham",
            "currency_symbol": "Moroccan Dirham",
            "phone_code": "212",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db1c"
        },
        {
            "name": "Yemen",
            "iso2": "YE",
            "iso3": "YEM",
            "currency": "YER",
            "currency_name": "Yemeni rial",
            "currency_symbol": "Yemeni rial",
            "phone_code": "967",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db1d"
        },
        {
            "name": "Zambia",
            "iso2": "ZM",
            "iso3": "ZMB",
            "currency": "ZMW",
            "currency_name": "Zambian kwacha",
            "currency_symbol": "Zambian kwacha",
            "phone_code": "260",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db1e"
        },
        {
            "name": "Zimbabwe",
            "iso2": "ZW",
            "iso3": "ZWE",
            "currency": "ZWL",
            "currency_name": "Zimbabwe Dollar",
            "currency_symbol": "Zimbabwe Dollar",
            "phone_code": "263",
            "createdAt": "2023-05-17T10:59:16.035Z",
            "updatedAt": "2023-05-17T10:59:16.035Z",
            "id": "6464b38466ac86c570e8db1f"
        }
    ]